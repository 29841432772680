import {Configuration as MsalConfiguration} from "@azure/msal-browser";
import deepmerge from "deepmerge";

import {Environment} from './types';

// IMPORTANT: These will be overwritten at build-time by build_ui.sh
const environmentName: Environment = 'dev';
const version: string = 'dev';

type CorsAnywhereConfig = {
  enabled: boolean;
  prefix: string;
  headers: { [k: string]: string };
}
const corsAnywhere: CorsAnywhereConfig = {
  // TODO: This should never make it into prod
  enabled: true,
  prefix: 'http://localhost:7080/',
  headers: {
    'X-Requested-With': 'Cors-anywhere'
  }
};

type ExtendedMsalConfiguration = MsalConfiguration & {
  scopes: string[],
}
const msalPreProd: ExtendedMsalConfiguration = {
  auth: {
    clientId: 'ce537fe8-00f7-42b5-8ef6-700d73d54c2e',
    authority: 'https://ndpb2cdirectory.b2clogin.com/ndpb2cdirectory.onmicrosoft.com/B2C_1A_Turas_SignIn_tst', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    knownAuthorities: ['ndpb2cdirectory.b2clogin.com'],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  scopes: [],
};

const msalProd: ExtendedMsalConfiguration = {
  auth: {
    clientId: '427248ef-4c8d-43de-8983-9a6482beecac',
    authority: 'https://nhsefs.b2clogin.com/nhsefs.onmicrosoft.com/B2C_1A_TURAS_SIGNIN_PRD', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    knownAuthorities: ['nhsefs.b2clogin.com'],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  scopes: [],
};

const sandboxApiEndpoints = {
  user: {
    userinfo: '/user/me/userinfo',
    postLogin: '/user/me/post-login',
    acceptTerms: '/user/me/accept-terms'
  }
}

type AdminApiEnv = {
  url: string,
  endpoints: typeof sandboxApiEndpoints,
  msalConfig: ExtendedMsalConfiguration,
  corsAnywhere?: CorsAnywhereConfig
}
const adminApiEnvs: { [k: string]: AdminApiEnv } = {
  'local': {
    url: 'http://localhost:8000',
    endpoints: sandboxApiEndpoints,
    msalConfig: {
      ...msalPreProd,
      auth: {
        ...msalPreProd.auth,
        redirectUri: 'http://localhost:3000/login-callback',
      },
    },
  },
  'dev': {
    url: 'https://sandbox-admin.dev.platform.ndp.scot',
    endpoints: sandboxApiEndpoints,
    msalConfig: {
      ...msalPreProd,
      auth: {
        ...msalPreProd.auth,
        redirectUri: 'https://developer.dev.platform.ndp.scot/login-callback',
      },
    },
  },
  'qa': {
    url: 'https://sandbox-admin.qa.platform.ndp.scot',
    endpoints: sandboxApiEndpoints,
    msalConfig: {
      ...msalPreProd,
      auth: {
        ...msalPreProd.auth,
        redirectUri: 'https://developer.qa.platform.ndp.scot/login-callback',
      },
    },
  },
  'staging': {
    url: 'https://sandbox-admin.staging.platform.ndp.scot',
    endpoints: sandboxApiEndpoints,
    msalConfig: {
      ...msalProd,
      auth: {
        ...msalProd.auth,
        redirectUri: 'https://developer.staging.platform.ndp.scot/login-callback',
      },
    },
  }
}

type FhirWorksApiEnv = { url: string, oauth: { webAppCallbackUrl: string }, corsAnywhere?: CorsAnywhereConfig }
const fhirWorksEnvs: { [k: string]: FhirWorksApiEnv } = {
  'local': {
    url: 'https://ndp-clinical-sandbox-fhir.dev.platform.ndp.scot',
    oauth: {
      webAppCallbackUrl: 'http://localhost:3000/callback'
    },
    corsAnywhere,
  },
  'dev': {
    url: 'https://ndp-clinical-sandbox-fhir.dev.platform.ndp.scot',
    oauth: {
      webAppCallbackUrl: 'https://developer.dev.platform.ndp.scot/callback'
    },
    corsAnywhere,
  },
  'qa': {
    url: 'https://ndp-clinical-sandbox-fhir.qa.platform.ndp.scot',
    oauth: {
      webAppCallbackUrl: 'https://developer.qa.platform.ndp.scot/callback',
    },
    corsAnywhere,
  },
  'staging': {
    url: 'https://ndp-clinical-sandbox-fhir.staging.platform.ndp.scot',
    oauth: {
      webAppCallbackUrl: 'https://developer.staging.platform.ndp.scot/callback',
    },
    corsAnywhere,
  }
}

type MediaStoreEnv = {
  corsAnywhere?: CorsAnywhereConfig,
  tryIt: {
    maxFileUploadSize: number,
    multipartMinFileSize: number,
    minPartSize: number,
    maxNumParts: number,
  }
};
const mediaStoreDefaults: MediaStoreEnv = {
  corsAnywhere,
  tryIt: {
    maxFileUploadSize: 1024 * 1024 * 50, // 50 MiB
    maxNumParts: 5,
    minPartSize: 1024 * 1024 * 5,// arbitrary value at the moment, could customize
    multipartMinFileSize: 1024 * 1024 * 10, // 20 MiB
  }
}

type EmpiApiEnv = { url: string, corsAnywhere?: CorsAnywhereConfig }
const empiEnvs: { [k: string]: EmpiApiEnv } = {
  'local': {
    url: 'http://localhost:9000',
  },
  'dev': {
    url: 'https://empi.sandbox.dev.platform.ndp.scot',
  },
  'qa': {
    url: 'https://empi.sandbox.qa.platform.ndp.scot',
  },
  'staging': {
    url: 'https://empi.sandbox.staging.platform.ndp.scot',
  }
}

type VaccinationApiEnv = { url: string, corsAnywhere?: CorsAnywhereConfig }
const vaccinationsEnvs: { [k: string]: VaccinationApiEnv } = {
  'local': {
    url: 'http://localhost:9000',
  },
  'dev': {
    url: 'https://vaccinations.sandbox.dev.platform.ndp.scot',
  },
  'qa': {
    url: 'https://vaccinations.sandbox.qa.platform.ndp.scot',
  },
  'staging': {
    url: 'https://vaccinations.sandbox.staging.platform.ndp.scot',
  }
}

type RandomDataApiEnv = {
  url: string;
}
const randomDataApiDefault: RandomDataApiEnv = {
  url: 'https://random-data.platform.ndp.scot/fhir/'
}

type EnvironmentConfig = {
  googleAnalytics?: {
    trackingId: string;
  };
  longName: string;
  name: string;
  adminApi: AdminApiEnv;
  fhirWorksApi: FhirWorksApiEnv;
  empiApi: EmpiApiEnv | undefined;
  vaccinationsApi: VaccinationApiEnv | undefined;
  randomDataApi: RandomDataApiEnv;
  mediaStore: MediaStoreEnv;
}

const EnvironmentDefaults: Partial<EnvironmentConfig> = {
  empiApi: undefined,
  vaccinationsApi: undefined,
  randomDataApi: randomDataApiDefault,
  mediaStore: mediaStoreDefaults,
}

const environments: { [k: string]: EnvironmentConfig } = {
  'local': deepmerge(EnvironmentDefaults, {
    longName: 'Local API',
    name: 'local',
    // DEVS: You can change any of the below 'dev' configs to 'local' to test local APIs
    adminApi: {
      // use the dev config for Admin API...
      ...adminApiEnvs['dev'],
      // ... but use the local auth config
      msalConfig: adminApiEnvs['local'].msalConfig,
    },
    fhirWorksApi: fhirWorksEnvs['dev'],
    googleAnalytics: {
      trackingId: 'G-TNXY94WLE3'
    },
  }),
  'dev': deepmerge(EnvironmentDefaults, {
    longName: 'Dev',
    name: 'dev',
    adminApi: adminApiEnvs['dev'],
    fhirWorksApi: fhirWorksEnvs['dev'],
    googleAnalytics: {
      trackingId: 'G-TNXY94WLE3'
    },
  }),
  'qa': deepmerge(EnvironmentDefaults, {
    longName: 'QA',
    name: 'qa',
    adminApi: adminApiEnvs['qa'],
    fhirWorksApi: fhirWorksEnvs['qa'],
  }),
  'staging': deepmerge(EnvironmentDefaults, {
    longName: 'Staging',
    name: 'staging',
    adminApi: adminApiEnvs['staging'],
    fhirWorksApi: fhirWorksEnvs['staging'],
    googleAnalytics: {
      trackingId: 'G-DJB3N099NL'
    },
  }),
}


function buildConfig(env: Environment): EnvironmentConfig {
  // safety check, until we're ready for prod
  // @ts-ignore
  if (env === 'prod')
    throw new Error('Refusing to start in PROD mode');

  const newEnvironment = environments[env];

  // HACK: This is used by devs and testers to test disabled features
  if (localStorage['feature-toggles'] == 'disable') {
    console.warn(`Enabling all toggled features for environment ${environmentName}`);
    switch (environmentName) {
      case "local":
      case "dev":
        newEnvironment['empiApi'] = empiEnvs['dev']
        newEnvironment['vaccinationsApi'] = vaccinationsEnvs['dev']
        break;
    }
  }

  return newEnvironment;
}

export function developerEnableToggledFeatures() {
  // HACK: This is used by devs and testers to test disabled features
  if (localStorage['feature-toggles'] === 'disable') {
    console.warn('DISABLING toggled features');
    localStorage.removeItem('feature-toggles');
    return;
  }

  console.warn(`ENABLING toggled features`);
  localStorage['feature-toggles'] = 'disable';
}

const environment: EnvironmentConfig = buildConfig(environmentName);

export {
  environmentName,
  version,
  environment,
  environments,
}
